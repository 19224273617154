define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["en-us", {
    "about": {
      "standout": {
        "text": "Simplifying innovation and reimagining sustainability",
        "title": "One-of-a-kind products"
      },
      "text": "Simplifying innovation and reimagining sustainability",
      "title": "About"
    },
    "account": {
      "alerts": {
        "list": {
          "access": {
            "text": "We will warn you when new devices access this interface.",
            "title": "Access"
          },
          "orders": {
            "text": "We will warn you when there is any relevant information about your purchases.",
            "title": "Orders & Shipping"
          },
          "usage": {
            "text": "We will warn you when we detect abnormal access to our APIs using your API Keys.",
            "title": "Usage"
          }
        },
        "text": "Configure how we warn you about account related events.",
        "title": "Alerts"
      },
      "delete": {
        "button": "Delete",
        "modalDelete": {
          "buttons": {
            "cancel": "Cancel",
            "delete": "Delete"
          },
          "text": "You are about to delete this account. This cannot be undone. Are you sure you want to continue?",
          "title": "Delete Account"
        },
        "text": "You can delete your account whenever you want. Please note that we will not refund any unused models. To proceed click the button below.",
        "title": "Delete Account"
      },
      "hero": {
        "text": "All you need to get you up and running today.",
        "title": "Manage your account"
      },
      "index": {
        "form": {
          "bio": {
            "label": "Bio",
            "placeholder": "Type something about you"
          },
          "button": "Save",
          "userName": {
            "label": "User name"
          }
        },
        "preferences": {
          "list": {
            "darkMode": {
              "text": "Toggle this option ON to experience our website in dark mode",
              "title": "Dark Mode Enabled"
            }
          },
          "text": "Customize your experience in our website.",
          "title": "Preferences"
        },
        "text": "Add some more information about yourself.",
        "title": "Profile"
      },
      "orders": {
        "text": "Please find below all the purchase orders you've made.",
        "title": "Orders"
      },
      "password": {
        "form": {
          "button": "Save",
          "confirmPassword": {
            "error": "Confirm new password",
            "label": "Confirm new password",
            "placeholder": "Type a password"
          },
          "newPassword": {
            "error": "Type the password",
            "label": "New password",
            "placeholder": "Type a password"
          },
          "password": {
            "error": "Type your old password",
            "label": "Current password",
            "placeholder": "Type your current password"
          }
        },
        "text": "Use the form below to change your password.",
        "title": "Change Password"
      }
    },
    "appDescription": "Bring your memories to life in stunning 3D glowing frames",
    "appName": "my3dframe.com",
    "blog": {
      "index": {
        "hero": {
          "text": "Discover, integrate, and empower your applications with our APIs.",
          "title": "One API to rule them all"
        },
        "text": "Keep up with our latest updates, news and events.",
        "title": "Blog"
      }
    },
    "cart": {
      "buttons": {
        "back": "Continue Shopping"
      },
      "text": "Proceed to checkout to purchase the items in your cart",
      "title": "Your Cart"
    },
    "checkout": {
      "buttons": {
        "back": "Back to Cart",
        "pay": "Pay Now"
      },
      "form": {
        "address": {
          "error": "Please type a address",
          "label": "Address",
          "placeholder": "Type the address"
        },
        "city": {
          "error": "Please type the city",
          "label": "City",
          "placeholder": "Type a city"
        },
        "company": {
          "error": "Please type your company name",
          "label": "Company",
          "placeholder": "Type your company name"
        },
        "country": {
          "error": "Please select the country",
          "label": "Country",
          "placeholder": "Select the country"
        },
        "email": {
          "error": "Please type your email",
          "label": "Email",
          "placeholder": "Type your email"
        },
        "firstName": {
          "error": "Please type your first name",
          "label": "First Name",
          "placeholder": "Type your first name"
        },
        "lastName": {
          "error": "Please type your last name",
          "label": "Last Name",
          "placeholder": "Type your last name"
        },
        "phone": {
          "error": "Please type the phone",
          "label": "Phone",
          "placeholder": "Type a phone"
        },
        "zipcode": {
          "error": "Please type a postal code",
          "label": "Postal Code",
          "placeholder": "Type the postal code"
        }
      },
      "payment": {
        "cc": {
          "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          "title": "Credit Card"
        },
        "ideal": {
          "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          "title": "Ideal"
        },
        "paypal": {
          "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          "title": "PayPal"
        }
      },
      "sections": {
        "contact": "Contact",
        "delivery": "Delivery",
        "payment": "Payment"
      },
      "text": "Provide all the required information below to complete your order",
      "title": "Checkout"
    },
    "collections": {
      "index": {
        "hero": {
          "text": "With plenty of 3D printing materials, settings and colors, you can find the option you need for your needs.",
          "title": "3D Printing Materials"
        },
        "text": "We offer a wide range of materials and colors.",
        "title": "Collections"
      }
    },
    "components": {
      "account": {
        "sidebar": {
          "alerts": "Alerts",
          "delete": "Delete Account",
          "logout": "Logout",
          "orders": "Orders",
          "password": "Change Password",
          "profile": "Profile"
        }
      },
      "addToHome": {
        "text1": "You can install this app on your device, simply tap ",
        "text2": " and then `Add to Home Screen`."
      },
      "cart": {
        "checkout": {
          "summary": {
            "disclaimer": "Shipping automatically calculated when address is provided",
            "items": "Items",
            "shipping": "Shipping",
            "subtotal": "Subtotal",
            "total": "Total",
            "vat": "VAT"
          }
        },
        "noData": "Your cart is empty",
        "summary": {
          "button": "Checkout",
          "disclaimer": "Tax included and shipping calculated at checkout",
          "items": "Items",
          "subtotal": "Subtotal",
          "total": "Total",
          "vat": "VAT"
        }
      },
      "chart": {
        "noData": "No data found",
        "rangeSelector": {
          "placeholder": "Select an interval"
        },
        "top10": {
          "all": "see all",
          "dateSelector": {
            "placeholder": "Select a date"
          },
          "modalAll": {
            "buttons": {
              "close": "Close"
            }
          },
          "others": "Others"
        },
        "totalForDate": {
          "dateSelector": {
            "placeholder": "Select a date"
          }
        },
        "totalForPeriod": {
          "periodSelector": {
            "placeholder": "Select period"
          }
        }
      },
      "cookieManager": {
        "button": "Save",
        "header": "Cookies",
        "text": "This website uses cookies for performance and marketing purposes. For specific information regarding how these cookies may impact your experience please read our <a href=\"/cookies\">Cookie Policy</a> and <a href=\"/privacy\">Privacy Policy</a>.",
        "types": {
          "marketing": "Marketing",
          "performance": "Performance"
        }
      },
      "cta": {
        "email": {
          "subscribe": {
            "form": {
              "button": "Subscribe",
              "email": {
                "error": "Please type your email",
                "label": "Email"
              }
            }
          }
        }
      },
      "footer": {
        "copyright": "© {year} MSS Shop - All rights reserved",
        "emailSubscribe": {
          "header": "Signup to stay in the loop with our latest news, events and products"
        },
        "legal": {
          "cookies": "Cookies",
          "privacy": "Privacy Policy",
          "refunds": "Refund Policy",
          "terms": "Terms & Conditions"
        },
        "navigation": {
          "account": {
            "buttons": {
              "orders": "Orders",
              "profile": "Profile"
            },
            "header": "Account"
          },
          "collections": {
            "header": "Collection"
          },
          "resources": {
            "buttons": {
              "about": "About",
              "blog": "Blog",
              "contact": "Contact",
              "faq": "FAQs"
            },
            "header": "Resources"
          }
        }
      },
      "navigation": {
        "buttons": {
          "about": "About",
          "collections": "Collections",
          "contact": "Contact",
          "login": "Login"
        }
      },
      "order": {
        "details": {
          "billingAddress": "Billing Address",
          "contact": "Contact Information",
          "shippingAddress": "Shipping Address",
          "shippingMethod": "Shipping Method"
        },
        "summary": {
          "disclaimer": "Shipping automatically calculated when address is provided",
          "items": "Items",
          "shipping": "Shipping",
          "subtotal": "Subtotal",
          "total": "Total",
          "vat": "VAT"
        }
      },
      "tabs": {
        "cart": "Cart",
        "home": "Home",
        "settings": "Settings"
      },
      "upload": {
        "dragdrop": {
          "errors": {
            "upload": {
              "specific": "Error: {error}",
              "unknown": "There were errors while uploading. Please try again later."
            },
            "validation": "There were errors while validating these files: {errors}"
          },
          "input": {
            "text": "click here"
          },
          "text": "Drag & drop files here or",
          "uploading": "Uploading, please wait..."
        },
        "field": {
          "chooseFile": "Select a file",
          "chooseFiles": "Select one or more files",
          "errors": {
            "upload": {
              "specific": "Error: {error}",
              "unknown": "There were errors while uploading. Please try again later."
            },
            "validation": "There were errors while validating these files: {errors}"
          },
          "protected": {
            "label": "Protect file",
            "tooltip": "When this option is toggled ON, we will store the file in a protected directory that is not publicly available."
          },
          "uploading": "Uploading, please wait..."
        },
        "picker": {
          "external": {
            "error": "Please provide a valid URL",
            "label": "External file",
            "placeholder": "Type or paste a valid URL",
            "tooltip": "Use this area to provide an external file publicly available in another location."
          },
          "protected": {
            "label": "Protect file",
            "tooltip": "When this option is toggled ON, we will store the file in a protected directory that is not publicly available."
          },
          "search": {
            "label": "Search file",
            "placeholder": "Search files",
            "tooltip": "Use this area to search an existing file previously uploaded in Notificare."
          },
          "tabs": {
            "external": "External URL",
            "search": "Search Existing",
            "upload": "New File"
          },
          "upload": {
            "label": "Upload file",
            "tooltip": "Use this area to upload a new file."
          }
        }
      }
    },
    "contact": {
      "faq": {
        "title": "FAQ"
      },
      "form": {
        "button": "Send",
        "email": {
          "error": "Please type your email",
          "label": "Email",
          "placeholder": "Type your email"
        },
        "firstName": {
          "error": "Please type your first name",
          "label": "First Name",
          "placeholder": "Type your first name"
        },
        "lastName": {
          "error": "Please type your last name",
          "label": "Last Name",
          "placeholder": "Type your last name"
        },
        "message": {
          "error": "Please type the message",
          "label": "Message",
          "placeholder": "Type a message"
        },
        "subject": {
          "error": "Please type a subject",
          "label": "Subject",
          "placeholder": "Type the subject"
        },
        "text": "Drop us a line we will get back to you as soon as possible.",
        "title": "Still have questions?",
        "type": {
          "error": "Please select the type",
          "label": "Type",
          "placeholder": "Select the type"
        }
      },
      "text": "You can always get in touch for any question you may have. Everyday between 9am and 9pm.",
      "title": "Contact"
    },
    "cookies": {
      "hero": {
        "text": "Discover, integrate, and empower your applications with our APIs.",
        "title": "One API to rule them all"
      }
    },
    "error": {
      "text": "We have encountered an unrecoverable error. This could be caused by recently deleted data, a bug in this application or server errors.<br><br>If the problem persists please contact our <a href=\"mailto:support@notifica.re\">support team</a>.",
      "title": "Error"
    },
    "fail": {
      "text": "We have encountered an unrecoverable error. It seems that the subdomain provided is not valid or configured.<br><br>If the problem persists please contact our <a href=\"mailto:support@notifica.re\">support team</a>.",
      "title": "Failed"
    },
    "faqs": {
      "hero": {
        "text": "No question is too big or too small - we probably have the answer.",
        "title": "Get in touch"
      },
      "text": "We've got the answers for all your questions.",
      "title": "FAQs"
    },
    "home": {
      "collections": "Explore our Collection",
      "featured": "Must haves",
      "hero": {
        "text": "From one to thousand parts, we've got your back.",
        "title": "Low volume manufacturing"
      },
      "testimonials": "What other customers say",
      "title": "Welcome"
    },
    "login": {
      "form": {
        "button": "Sign-in",
        "email": {
          "error": "Please type your email",
          "label": "Email"
        },
        "password": {
          "error": "Please type your password",
          "label": "Password"
        }
      },
      "recoverPassword": "Forgot Password?",
      "signup": {
        "link": "Create one here.",
        "text": "Don't have an account?"
      },
      "text": "Please sign-in to access your account",
      "title": "Welcome guest..."
    },
    "order": {
      "buttons": {
        "back": "Back to Cart",
        "continue": "Continue Shopping"
      },
      "delivery": {
        "steps": {
          "confirmed": "Confirmed",
          "delivered": "Delivered",
          "shipped": "On its way",
          "transit": "In transit"
        },
        "text": "Your order has been delivered to the address you provided. If you haven’t received it, or if you have any other problems, <a href=\"mailto:info@domain.com\">contact us</a>.",
        "title": "Your order was delivered",
        "trackingNr": "Tracking Number:<br><a href=\"{trackingNr}\">{trackingNr}</a>"
      },
      "sections": {
        "delivery": "Delivery",
        "details": "Order Details"
      },
      "text": "Thank you for your purchase.",
      "title": "Order #{orderId}"
    },
    "privacy": {
      "hero": {
        "text": "Discover, integrate, and empower your applications with our APIs.",
        "title": "One API to rule them all"
      }
    },
    "products": {
      "details": {
        "addToCart": "Add to Cart",
        "addToCartMessage": "Product added to cart. Thank you!",
        "color": {
          "label": "Color:"
        },
        "quantity": {
          "label": "Quantity:"
        }
      },
      "index": {
        "featured": {
          "text": "Explore our essentials products.",
          "title": "Featured Products"
        },
        "text": "Explore all one-of-kind products we have for you.",
        "title": "All Products"
      }
    },
    "recoverPassword": {
      "form": {
        "button": "Recover Password",
        "email": {
          "error": "Please type your email",
          "label": "Email"
        }
      },
      "text": "Please provide your email address to recover your password",
      "title": "That's a shame..."
    },
    "refunds": {
      "hero": {
        "text": "Discover, integrate, and empower your applications with our APIs.",
        "title": "One API to rule them all"
      }
    },
    "resetPassword": {
      "form": {
        "button": "Reset Password",
        "confirmPassword": {
          "error": "Please confirm your password",
          "label": "Confirm Password",
          "mismatch": "Passwords don't match"
        },
        "password": {
          "error": "Please type a password",
          "label": "Password"
        }
      },
      "text": "Please provide a new password to continue",
      "title": "Almost there..."
    },
    "restricted": {
      "text": "You do not have access to this page.",
      "title": "Restricted"
    },
    "shared": {
      "comingSoon": "coming soon",
      "error": {
        "generic": "<h4>Error!</h4>We could not process this request due to an unknown error.",
        "specific": "<h4>Error!</h4>{error}"
      },
      "home": "Home",
      "on": "On",
      "or": "or",
      "postedIn": "Posted in",
      "poweredBy": "Powered by",
      "success": "<h4>Done!</h4>Your request was processed successfully."
    },
    "signup": {
      "form": {
        "button": "Create Account",
        "confirmPassword": {
          "error": "Please confirm your password",
          "label": "Confirm Password",
          "mismatch": "Passwords don't match"
        },
        "email": {
          "error": "Please type an email address",
          "label": "Email"
        },
        "firstName": {
          "error": "Please type your first name",
          "label": "First Name"
        },
        "lastName": {
          "error": "Please type your last name",
          "label": "Last Name"
        },
        "password": {
          "error": "Please type a password",
          "label": "Password"
        }
      },
      "login": {
        "link": "Login here.",
        "text": "Already have an account?"
      },
      "text": "Fill in the fields below to create an account",
      "title": "Signup"
    },
    "support": {
      "form": {
        "button": "Send",
        "email": {
          "error": "Please type your email",
          "label": "Email",
          "placeholder": "Type your email"
        },
        "firstName": {
          "error": "Please type your first name",
          "label": "First Name",
          "placeholder": "Type your first name"
        },
        "lastName": {
          "error": "Please type your last name",
          "label": "Last Name",
          "placeholder": "Type your last name"
        },
        "message": {
          "error": "Please type the message",
          "label": "Message",
          "placeholder": "Type a message"
        },
        "subject": {
          "error": "Please type a subject",
          "label": "Subject",
          "placeholder": "Type the subject"
        },
        "type": {
          "error": "Please select the type",
          "label": "Type",
          "placeholder": "Select the type"
        }
      },
      "hero": {
        "text": "No question is too big or too small - we probably have the answer.",
        "title": "Get in touch"
      },
      "text": "Use the form below to reach our team.",
      "title": "Support"
    },
    "terms": {
      "hero": {
        "text": "Discover, integrate, and empower your applications with our APIs.",
        "title": "One API to rule them all"
      }
    },
    "unknown": {
      "text": "This page cannot be found or was recently removed",
      "title": "404"
    }
  }]];
});